import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { 
  NbSidebarModule, 
  NbMenuModule,
  NbDialogModule,
  NbCardModule,
  NbButtonModule,
  NbWindowModule,
  NbIconModule,
  NbDatepickerModule,

  } from '@nebular/theme';
import { RouterModule } from '@angular/router';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { HttpClientModule } from '@angular/common/http';
import { NgxEchartsModule } from 'ngx-echarts';
import { ThemeModule } from './@theme/theme.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NbMomentDateModule } from '@nebular/moment';
import { LOCALE_ID } from '@angular/core';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    RouterModule,
    BrowserModule,
    HttpClientModule,


    AppRoutingModule,
    BrowserAnimationsModule,
    
    NbSidebarModule.forRoot(), 
    NbMenuModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbCardModule,
    NbButtonModule,
    NbIconModule,
    ThemeModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbEvaIconsModule,
    NgSelectModule,
    NbMomentDateModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    })
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'zh-cn' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
