import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span >
      设计制件 ♥ 翁志来 2020
    </span>
  `,
})
export class FooterComponent {
}
